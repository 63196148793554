const actions = {
	GET_SEL_CHAPTER_REQUEST: 'GET_SEL_CHAPTER_REQUEST',
	GET_SEL_CHAPTER_SUCCESS: 'GET_SEL_CHAPTER_SUCCESS',
	GET_SEL_CHAPTER_FAILED: 'GET_SEL_CHAPTER_FAILED',

	GET_CHAPTER_REQUEST: 'GET_CHAPTER_REQUEST',
	GET_CHAPTER_SUCCESS: 'GET_CHAPTER_SUCCESS',
	GET_CHAPTER_FAILED: 'GET_CHAPTER_FAILED',

	GET_SCHAPTER_RESULT_REQUEST: 'GET_SCHAPTER_RESULT_REQUEST',
	GET_SCHAPTER_RESULT_SUCCESS: 'GET_SCHAPTER_RESULT_SUCCESS',
	GET_SCHAPTER_RESULT_FAILED: 'GET_SCHAPTER_RESULT_FAILED',

	SET_CHAPTER_RESULT_REQUEST: 'SET_CHAPTER_RESULT_REQUEST',
	SET_CHAPTER_RESULT_SUCCESS: 'SET_CHAPTER_RESULT_SUCCESS',
	SET_CHAPTER_RESULT_FAILED: 'SET_CHAPTER_RESULT_FAILED',

	UPDATE_CHAPTER_RESULT_REQUEST: 'UPDATE_CHAPTER_RESULT_REQUEST',
	UPDATE_CHAPTER_RESULT_SUCCESS: 'UPDATE_CHAPTER_RESULT_SUCCESS',
	UPDATE_CHAPTER_RESULT_FAILED: 'UPDATE_CHAPTER_RESULT_FAILED',

	SET_SCORE_VALUE_REQUEST: 'SET_SCORE_VALUE_REQUEST',
	SET_SCORE_VALUE_SUCCESS: 'SET_SCORE_VALUE_SUCCESS',
	SET_SCORE_VALUE_FAILED: 'SET_SCORE_VALUE_FAILED',

	SET_ALREADY_TAKEN_CHAPTER_DATA: 'SET_ALREADY_TAKEN_CHAPTER_DATA',

	setAlreadyTakenChatperData: payload => ({
		type: actions.SET_ALREADY_TAKEN_CHAPTER_DATA,
		payload
	}),

	getSelChapter: payload => ({
		type: actions.GET_SEL_CHAPTER_REQUEST,
		payload
	}),
	getSelChapterSuccess: payload => ({
		type: actions.GET_SEL_CHAPTER_SUCCESS,
		payload
	}),
	getSelChapterFailed: payload => ({
		type: actions.GET_SEL_CHAPTER_FAILED,
		payload
	}),

	getChapter: payload => ({
		type: actions.GET_CHAPTER_REQUEST,
		payload
	}),
	getChapterSuccess: payload => ({
		type: actions.GET_CHAPTER_SUCCESS,
		payload
	}),
	getChapterFailed: payload => ({
		type: actions.GET_CHAPTER_FAILED,
		payload
	}),

	getSChapterResult: payload => ({
		type: actions.GET_SCHAPTER_RESULT_REQUEST,
		payload
	}),
	getSChapterResultSuccess: payload => ({
		type: actions.GET_SCHAPTER_RESULT_SUCCESS,
		payload
	}),
	getSChapterResultFailed: payload => ({
		type: actions.GET_SCHAPTER_RESULT_FAILED,
		payload
	}),

	setChapterResult: payload => ({
		type: actions.SET_CHAPTER_RESULT_REQUEST,
		payload
	}),
	setChapterResultSuccess: payload => ({
		type: actions.SET_CHAPTER_RESULT_SUCCESS,
		payload
	}),
	setChapterResultFailed: payload => ({
		type: actions.SET_CHAPTER_RESULT_FAILED,
		payload
	}),

	updateChapterResult: payload => ({
		type: actions.UPDATE_CHAPTER_RESULT_REQUEST,
		payload
	}),
	updateChapterResultSuccess: payload => ({
		type: actions.UPDATE_CHAPTER_RESULT_SUCCESS,
		payload
	}),
	updateChapterResultFailed: payload => ({
		type: actions.UPDATE_CHAPTER_RESULT_FAILED,
		payload
	}),

	setScoreValue: payload => ({
		type: actions.SET_SCORE_VALUE_REQUEST,
		payload
	}),
	setScoreValueSuccess: payload => ({
		type: actions.SET_SCORE_VALUE_SUCCESS,
		payload
	}),
	setScoreValueFailed: payload => ({
		type: actions.SET_SCORE_VALUE_FAILED,
		payload
	})
}

export default actions
