import actions from './actions'

const initState = {
	challenges: [],
	detailData: [],
	sChapterResult: [],
	curChallenge: null,
	isLoading: false,
	registerTStatus: false,
	loginTStatus: false,
	loginInfo: {},
	errorMsg: null,
	resultSendMail: false,
	success: false,
	registerPIResult: false,
	notFound: false,
	sandUserData: null,
	ppRegResult: null
}

export default function sandboxReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.REGISTER_SAND_REQUEST:
			return {
				...state,
				sand_user: null,
				registerTStatus: false,
				errorMsg: null
			}

		case actions.REGISTER_SAND_SUCCESS:
			return {
				...state,
				sand_user: payload,
				registerTStatus: true,
				errorMsg: null
			}

		case actions.REGISTER_SAND_FAILED:
			return {
				...state,
				sand_user: null,
				registerTStatus: false,
				errorMsg: payload
			}

		case actions.LOGIN_SAND_REQUEST:
			return {
				...state,
				loginTStatus: false,
				user: null,
				errorMsg: null
			}

		case actions.LOGIN_SAND_SUCCESS:
			return {
				...state,
				loginTStatus: true,
				loginInfo: payload,
				user: payload,
				errorMsg: null
			}

		case actions.LOGIN_SAND_FAILED:
			return {
				...state,
				loginTStatus: false,
				user: null,
				errorMsg: payload
			}

		case actions.GET_SANDDATA_REQUEST:
			return {
				...state,
				getTStatus: false,
				sand_data: null
			}

		case actions.GET_SANDDATA_SUCCESS:
			return {
				...state,
				getTStatus: true,
				sand_data: payload
			}

		case actions.GET_SANDDATA_FAILED:
			return {
				...state,
				getTStatus: false,
				notFound: true,
				sand_data: null
			}

		case actions.GET_SANDDATA_PROFILE_REQUEST:
			return {
				...state,
				getTStatus: false,
				sand_data: null
			}

		case actions.GET_SANDDATA_PROFILE_SUCCESS:
			return {
				...state,
				getTStatus: true,
				sand_data: payload
			}

		case actions.GET_SANDDATA_PROFILE_FAILED:
			return {
				...state,
				getTStatus: false,
				sand_data: null
			}

		case actions.GET_ECHAPTER_RESULT_REQUEST:
			return {
				...state,
				errorMsg: null
			}
		case actions.GET_ECHAPTER_RESULT_SUCCESS:
			return {
				...state,
				sChapterResult: payload
			}
		case actions.GET_ECHAPTER_RESULT_FAILED:
			return {
				...state,
				errorMsg: payload
			}

		case actions.GET_ALL_CHALLENGE_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null
			}

		case actions.GET_ALL_CHALLENGE_SUCCESS:
			return {
				...state,
				isLoading: false,
				challenges: payload,
				curChallenge: null
			}

		case actions.GET_ALL_CHALLENGE_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}

		case actions.GET_CHALLENGE_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null
			}

		case actions.GET_CHALLENGE_SUCCESS:
			return {
				...state,
				isLoading: false,
				curChallenge: payload
			}

		case actions.GET_CHALLENGE_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}

		case actions.SET_PROFILE_INFO_REQUEST:
			return {
				...state,
				errorMsg: payload,
				registerPIResult: false
			}

		case actions.SET_PROFILE_INFO_SUCCESS:
			return {
				...state,
				sandUserData: payload,
				registerPIResult: true,
				errorMsg: null
			}

		case actions.SET_PROFILE_INFO_FAILED:
			return {
				...state,
				errorMsg: payload,
				registerPIResult: false
			}

		case actions.UPDATE_PROFILE_INFO_REQUEST:
			return {
				...state,
				errorMsg: null
			}

		case actions.UPDATE_PROFILE_INFO_SUCCESS:
			return {
				...state,
				detailData: payload
			}

		case actions.UPDATE_PROFILE_INFO_FAILED:
			return {
				...state,
				errorMsg: null
			}

		case actions.UPDATE_SANDBOX_RECORD_REQUEST:
			return {
				...state,
				errorMsg: null
			}

		case actions.UPDATE_SANDBOX_RECORD_SUCCESS:
			return {
				...state,
				errorMsg: null
			}

		case actions.UPDATE_SANDBOX_RECORD_FAILED:
			return {
				...state,
				errorMsg: null
			}

		case actions.UPDATE_SANDBOX_PPROFILE_REQUEST:
			return {
				...state,
				ppRegResult: null,
				errorMsg: null
			}

		case actions.UPDATE_SANDBOX_PPROFILE_SUCCESS:
			return {
				...state,
				ppRegResult: payload,
				errorMsg: null
			}

		case actions.UPDATE_SANDBOX_PPROFILE_FAILED:
			return {
				...state,
				ppRegResult: payload,
				errorMsg: null
			}

		case actions.GET_PROFILE_INFO_REQUEST:
			return {
				...state,
				errorMsg: null
			}

		case actions.GET_PROFILE_INFO_SUCCESS:
			return {
				...state,
				detailData: payload,
				errorMsg: null
			}

		case actions.GET_PROFILE_INFO_FAILED:
			return {
				...state,
				errorMsg: null
			}

		case actions.GET_SANDBOX_USERDATA_REQUEST:
			return {
				...state,
				isLoading: false
			}

		case actions.GET_SANDBOX_USERDATA_SUCCESS:
			return {
				...state,
				isLoading: false,
				sandUserData: payload
			}

		case actions.GET_SANDBOX_USERDATA_FAILED:
			return {
				...state,
				isLoading: false,
				notFound: true,
				errorMsg: payload
			}

		case actions.SAVE_BADGE_VALUE_REQUEST:
			return {
				...state,
				isLoading: false,
				saveBadge: false
			}

		case actions.SAVE_BADGE_VALUE_SUCCESS:
			return {
				...state,
				isLoading: false,
				saveBadge: true
			}

		case actions.SAVE_BADGE_VALUE_FAILED:
			return {
				...state,
				isLoading: false,
				saveBadge: false
			}

		default:
			return state
	}
}
