const actions = {
	REGISTER_SAND_REQUEST: 'REGISTER_SAND_REQUEST',
	REGISTER_SAND_SUCCESS: 'REGISTER_SAND_SUCCESS',
	REGISTER_SAND_FAILED: 'REGISTER_SAND_FAILED',

	LOGIN_SAND_REQUEST: 'LOGIN_SAND_REQUEST',
	LOGIN_SAND_SUCCESS: 'LOGIN_SAND_SUCCESS',
	LOGIN_SAND_FAILED: 'LOGIN_SAND_FAILED',

	GET_ALL_CHALLENGE_REQUEST: '@SANDBOX_GET_ALL_CHALLENGE_REQUEST',
	GET_ALL_CHALLENGE_SUCCESS: '@SANDBOX_GET_ALL_CHALLENGE_SUCCESS',
	GET_ALL_CHALLENGE_FAILED: '@SANDBOX_GET_ALL_CHALLENGE_FAILED',

	GET_CHALLENGE_REQUEST: '@SANDBOX_GET_CHALLENGE_REQUEST',
	GET_CHALLENGE_SUCCESS: '@SANDBOX_GET_CHALLENGE_SUCCESS',
	GET_CHALLENGE_FAILED: '@SANDBOX_GET_CHALLENGE_FAILED',

	SET_PROFILE_INFO_REQUEST: 'SET_PROFILE_INFO_REQUEST',
	SET_PROFILE_INFO_SUCCESS: 'SET_PROFILE_INFO_SUCCESS',
	SET_PROFILE_INFO_FAILED: 'SET_PROFILE_INFO_FAILED',

	UPDATE_PROFILE_INFO_REQUEST: 'UPDATE_PROFILE_INFO_REQUEST',
	UPDATE_PROFILE_INFO_SUCCESS: 'UPDATE_PROFILE_INFO_SUCCESS',
	UPDATE_PROFILE_INFO_FAILED: 'UPDATE_PROFILE_INFO_FAILED',

	UPDATE_SANDBOX_RECORD_REQUEST: 'UPDATE_SANDBOX_RECORD_REQUEST',
	UPDATE_SANDBOX_RECORD_SUCCESS: 'UPDATE_SANDBOX_RECORD_SUCCESS',
	UPDATE_SANDBOX_RECORD_FAILED: 'UPDATE_SANDBOX_RECORD_FAILED',

	UPDATE_SANDBOX_PPROFILE_REQUEST: 'UPDATE_SANDBOX_PPROFILE_REQUEST',
	UPDATE_SANDBOX_PPROFILE_SUCCESS: 'UPDATE_SANDBOX_PPROFILE_SUCCESS',
	UPDATE_SANDBOX_PPROFILE_FAILED: 'UPDATE_SANDBOX_PPROFILE_FAILED',

	GET_SANDDATA_REQUEST: 'GET_SANDDATA_REQUEST',
	GET_SANDDATA_SUCCESS: 'GET_SANDDATA_SUCCESS',
	GET_SANDDATA_FAILED: 'GET_SANDDATA_FAILED',

	GET_SANDDATA_PROFILE_REQUEST: 'GET_SANDDATA_PROFILE_REQUEST',
	GET_SANDDATA_PROFILE_SUCCESS: 'GET_SANDDATA_PROFILE_SUCCESS',
	GET_SANDDATA_PROFILE_FAILED: 'GET_SANDDATA_PROFILE_FAILED',

	GET_PROFILE_INFO_REQUEST: 'GET_PROFILE_INFO_REQUEST',
	GET_PROFILE_INFO_SUCCESS: 'GET_PROFILE_INFO_SUCCESS',
	GET_PROFILE_INFO_FAILED: 'GET_PROFILE_INFO_FAILED',

	GET_ECHAPTER_RESULT_REQUEST: 'GET_ECHAPTER_RESULT_REQUEST',
	GET_ECHAPTER_RESULT_SUCCESS: 'GET_ECHAPTER_RESULT_SUCCESS',
	GET_ECHAPTER_RESULT_FAILED: 'GET_ECHAPTER_RESULT_FAILED',

	GET_SANDBOX_USERDATA_REQUEST: 'GET_SANDBOX_USERDATA_REQUEST',
	GET_SANDBOX_USERDATA_SUCCESS: 'GET_SANDBOX_USERDATA_SUCCESS',
	GET_SANDBOX_USERDATA_FAILED: 'GET_SANDBOX_USERDATA_FAILED',

	SAVE_BADGE_VALUE_REQUEST: 'SAVE_BADGE_VALUE_REQUEST',
	SAVE_BADGE_VALUE_SUCCESS: 'SAVE_BADGE_VALUE_SUCCESS',
	SAVE_BADGE_VALUE_FAILED: 'SAVE_BADGE_VALUE_FAILED',

	registerTRequest: payload => ({
		type: actions.REGISTER_SAND_REQUEST,
		payload
	}),
	registerTSuccess: payload => ({
		type: actions.REGISTER_SAND_SUCCESS,
		payload
	}),
	registerTFailed: payload => ({
		type: actions.REGISTER_SAND_FAILED,
		payload
	}),

	loginTRequest: payload => ({ type: actions.LOGIN_SAND_REQUEST, payload }),
	loginTSuccess: payload => ({ type: actions.LOGIN_SAND_SUCCESS, payload }),
	loginTFailed: payload => ({ type: actions.LOGIN_SAND_FAILED, payload }),

	logoutTRequest: payload => ({ type: actions.LOGOUT_SAND_REQUEST, payload }),
	logoutTSuccess: payload => ({ type: actions.LOGOUT_SAND_SUCCESS, payload }),
	logoutTFailed: payload => ({ type: actions.LOGOUT_SAND_FAILED, payload }),

	getSandDataRequest: payload => ({
		type: actions.GET_SANDDATA_REQUEST,
		payload
	}),
	getSandDataSuccess: payload => ({
		type: actions.GET_SANDDATA_SUCCESS,
		payload
	}),
	getSandDataFailed: payload => ({
		type: actions.GET_SANDDATA_FAILED,
		payload
	}),

	getSandDataRequestByProfile: payload => ({
		type: actions.GET_SANDDATA_PROFILE_REQUEST,
		payload
	}),
	getSandDataRequestByProfileSuccess: payload => ({
		type: actions.GET_SANDDATA_PROFILE_SUCCESS,
		payload
	}),
	getSandDataRequestByProfileFailed: payload => ({
		type: actions.GET_SANDDATA_PROFILE_FAILED,
		payload
	}),

	setSandProfileInfoRequest: payload => ({
		type: actions.SET_PROFILE_INFO_REQUEST,
		payload
	}),
	setSandProfileInfoSuccess: payload => ({
		type: actions.SET_PROFILE_INFO_SUCCESS,
		payload
	}),
	setSandProfileInfoFailed: payload => ({
		type: actions.SET_PROFILE_INFO_FAILED,
		payload
	}),

	getAllChallenge: payload => ({
		type: actions.GET_ALL_CHALLENGE_REQUEST,
		payload
	}),
	getAllChallengeSuccess: payload => ({
		type: actions.GET_ALL_CHALLENGE_SUCCESS,
		payload
	}),
	getAllChallengeFailed: payload => ({
		type: actions.GET_ALL_CHALLENGE_FAILED,
		payload
	}),

	getChallenge: payload => ({
		type: actions.GET_CHALLENGE_REQUEST,
		payload
	}),
	getChallengeSuccess: payload => ({
		type: actions.GET_CHALLENGE_SUCCESS,
		payload
	}),
	getChallengeFailed: payload => ({
		type: actions.GET_CHALLENGE_FAILED,
		payload
	}),

	getEChapterResult: payload => ({
		type: actions.GET_ECHAPTER_RESULT_REQUEST,
		payload
	}),
	getEChapterResultSuccess: payload => ({
		type: actions.GET_ECHAPTER_RESULT_SUCCESS,
		payload
	}),
	getEChapterResultFailed: payload => ({
		type: actions.GET_ECHAPTER_RESULT_FAILED,
		payload
	}),

	updateProfileDetails: payload => ({
		type: actions.UPDATE_PROFILE_INFO_REQUEST,
		payload
	}),
	updateProfileDetailsSuccess: payload => ({
		type: actions.UPDATE_PROFILE_INFO_SUCCESS,
		payload
	}),
	updateProfileDetailsFailed: payload => ({
		type: actions.UPDATE_PROFILE_INFO_FAILED,
		payload
	}),

	updateSandboxRecord: payload => ({
		type: actions.UPDATE_SANDBOX_RECORD_REQUEST,
		payload
	}),
	updateSandboxRecordSuccess: payload => ({
		type: actions.UPDATE_SANDBOX_RECORD_SUCCESS,
		payload
	}),
	updateSandboxRecordFailed: payload => ({
		type: actions.UPDATE_SANDBOX_RECORD_FAILED,
		payload
	}),

	updateSandPublicProfile: payload => ({
		type: actions.UPDATE_SANDBOX_PPROFILE_REQUEST,
		payload
	}),
	updateSandPublicProfileSuccess: payload => ({
		type: actions.UPDATE_SANDBOX_PPROFILE_SUCCESS,
		payload
	}),
	updateSandPublicProfileFailed: payload => ({
		type: actions.UPDATE_SANDBOX_PPROFILE_FAILED,
		payload
	}),

	getProfileDetails: payload => ({
		type: actions.GET_PROFILE_INFO_REQUEST,
		payload
	}),
	getProfileDetailsSuccess: payload => ({
		type: actions.GET_PROFILE_INFO_SUCCESS,
		payload
	}),
	getProfileDetailsFailed: payload => ({
		type: actions.GET_PROFILE_INFO_FAILED,
		payload
	}),

	getSandboxUser: payload => ({
		type: actions.GET_SANDBOX_USERDATA_REQUEST,
		payload
	}),
	getSandboxUserdataSuccess: payload => ({
		type: actions.GET_SANDBOX_USERDATA_SUCCESS,
		payload
	}),
	getSandboxUserdataFailed: payload => ({
		type: actions.GET_SANDBOX_USERDATA_FAILED,
		payload
	}),

	saveBadgeValue: payload => ({
		type: actions.SAVE_BADGE_VALUE_REQUEST,
		payload
	}),
	saveBadgeValueSuccess: payload => ({
		type: actions.SAVE_BADGE_VALUE_SUCCESS,
		payload
	}),
	saveBadgeValueFailed: payload => ({
		type: actions.SAVE_BADGE_VALUE_FAILED,
		payload
	})
}

export default actions
