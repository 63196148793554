import React from 'react'
import { ReactComponent as Spinner } from '../../images/tail-spin.svg'

const Loading = () => (
	<div className="svg-loader">
		<Spinner className="bx-spin" />
	</div>
)

export default Loading
