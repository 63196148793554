import React from 'react'

export const EsDescription = () => (
	<div>
		<p>
			Nugget.ai Corp. ("Nugget.ai") valora su privacidad y este aviso revela las prácticas
			de privacidad ("Política de privacidad"), que se aplican a todos los sitios web,
			aplicaciones y nuestros servicios relacionados proporcionados por nuestra empresa.
			Para los fines de esta Política, cualquier referencia a "nosotros", "nuestro" o
			"nosotros" significa Nugget.ai.
		</p>
		<p>
			En este documento, Nugget.ai describe nuestra colección, uso y el intercambio
			ocasional de información que usted nos proporciona, incluyendo pero no se limita a, al
			utilizar nuestro sitio web y aplicaciones, o cualquier software proporcionado por
			Nugget.ai. Podemos actualizar esta Política de vez en cuando para Reflejar cambios en
			nuestras prácticas, operacionales, legales o regulatorios. razones. Si esos son
			materiales, publicaremos esta política revisada en este sitio web.
		</p>
		<p>
			TENGA EN CUENTA QUE AL ACCEDER Y UTILIZAR NUESTRO SITIO WEB Y NUESTRAS APLICACIONES,
			USTED REPRESENTA Y GARANTIZA QUE HA LEÍDO Y ENTENDIDO, Y ACEPTA LOS TÉRMINOS DE ESTA
			POLÍTICA DE PRIVACIDAD. SI NO ESTÁ DE ACUERDO CON ESTE TÉRMINO, POR FAVOR, NO ACCESO
			NI UTILICE ESTE SITIO WEB Y SOLUCIONES.
		</p>
		<h3>1. Recopilación, uso y uso de la información.</h3>
		<p>
			Recopilamos diferentes tipos de datos de acuerdo a su uso de la aplicación. y el sitio
			web. Entre los tipos de Datos Personales que potencialmente podemos son: cookies,
			datos de uso, ubicación geográfica, direcciones de correo electrónico, e información
			demográfica. Mientras se proporciona la mayoría de estos serán voluntaria, la negativa
			a hacerlo puede impedirle utilizar algunos de nuestros Características y servicios.
		</p>
		<p>
			Cuando accede a nuestro sitio web, podemos recopilar y retener automáticamente 
			Información pasiva, incluida la dirección de protocolo de Internet de sus
			dispositivos  (IP), e información como navegador, sistema operativo y plataforma, zona
			horaria, e información de uso. También utilizamos cookies y tecnologías
			(como controles integrados y balizas web) para ayudarnos a realizar un seguimiento
			eficaz del uso de Nuestro sitio web y aplicaciones, con la asistencia de análisis de
			terceros. software (como Google Analytics).
		</p>
		<h3>2.¿Cómo usamos su información?</h3>
		<p>
			En nuestro sitio web público y aplicaciones, su información personal es recopilados
			para mejorar su experiencia al utilizar nuestros servicios y aplicaciones Mientras que
			generalmente eres capaz de bloquear el intercambio de información a través de la
			configuración en la configuración de su navegador, esto puede Evitar el acceso o dañar
			su experiencia en el uso de nuestros servicios.
		</p>
		<p>
			La misión de Nugget.ai es ayudar a las personas a comprender mejor sus habilidades
			sociales,Ayudándoles a encontrar oportunidades para el desarrollo personal y nuevas
			trayectorias profesionales.Al mismo tiempo, ayudamos a las empresas a contratar,
			retener y desarrollar su talento,Creando programas que utilicen datos públicos y
			privados de sus propios medios.compañías. Al utilizar nuestras aplicaciones, entiendes
			que eresconsintiendo que su información pueda ser visible para su empleador si es
			partede uno de estos programas. Si elige utilizar nuestros servicios yaplicaciones a
			través de una aplicación de terceros (como las juntas de trabajo,servicios de
			reclutamiento, y otros), también acepta otorgar esteAcceso de terceros a sus datos de
			Nugget.ai.
		</p>
		<h3>3.Su acceso y control sobre la información</h3>
		<p>
			Nugget.ai retiene su información provista al momento de registrarsefines comerciales
			legítimos y para cumplir con la ley, y adopta unamarco de seguridad responsable (a
			continuación) para proteger su privacidad individualDerechos y uso de sus datos.
		</p>
		<p>
			Usted puede optar por no recibir más contactos de nosotros en cualquier momento y
			tener laderechos de estar informados, acceso y exclusión de sus datos.
			Específicamente,en Nugget.ai estamos comprometidos con la justificación del marco
			GDPR, yTienen ciertos derechos en virtud del derecho europeo. Si quieres ejercitar
			estosderechos, puede hacerlo en cualquier momento poniéndose en contacto con nosotros
			a través de la dirección de correo electrónico(privacy@nugget.ai) o nuestro formulario
			de contacto en el sitio web.
		</p>
		<p>
			Tenga en cuenta que es posible que deba conservarse cierta información según lo exige
			la ley.     
		</p>
		    <h3> 4. Medidas de seguridad </h3>
		    
		<p>
			Tomamos precauciones para proteger su información. Cuando usted envía
			sensibleinformación a través del sitio web, su información está protegida tanto en
			línea comodesconectado.
		</p>
		    
		<p>
			Donde sea que recopilemos información confidencial (como sus datos personales),Esa
			información se encripta y nos transmite de manera segura. Túpuede verificar esto
			buscando un ícono de candado en la barra de direcciones y buscandopara "https" al
			comienzo de la dirección de la página web.
		</p>
		    
		<p>
			Si bien utilizamos el cifrado para proteger la información sensible transmitidaEn
			línea, también protegemos su información fuera de línea. Solo empleados que
			necesitenLa información para realizar un trabajo específico se concede acceso a
			personalInformación identificable. Las computadoras / servidores en los que
			almacenamosLa información de identificación personal se guarda en un entorno seguro.
		</p>
		    
		<p>
			Alojamos datos y aplicaciones en proveedores de nube de primer nivel con
			reconocidoscertificaciones de seguridad de la industria, así como el uso de software
			que ha cumplidoLos más altos estándares en la industria. Si quieres tener acceso a
			esoinformación, por favor solicite que a la dirección de correo electrónico
			privacy@nugget.ai,indicando razonamiento para esa solicitud.
		</p>
		    <h3> 5. Información de contacto </h3>
		    
		<p>
			Si cree que no estamos cumpliendo con esta política de privacidad, o si deberíatiene
			alguna pregunta, por favor contáctenos inmediatamente por correo electrónico
			aprivacy@nugget.ai o utilizando los contactos a continuación:
		</p>
		<p>
			Nugget.ai Corp.
			<br />
			ATTN: Chief Executive Officer
			<br />
			185 Spadina Avenue
			<br />
			Toronto, ON M5T 2C6
			<br />
			Canada
		</p>
	</div>
)

export const EnDescription = () => (
	<div>
		<p>
			Nugget.ai Corp. (“Nugget.ai”) values your privacy and this notice discloses the
			privacy practices (“Privacy Policy”), applying to all websites, applications and our
			related services provided by our company. For the purpose of this Policy, any
			references to “we”, “our”, or “us”, means Nugget.ai.
		</p>
		<p>
			Within this document, Nugget.ai outlines our collection, use, and occasional sharing
			of information that you provide to us, including but not limited to, when using our
			website and applications, or any software provided by Nugget.ai. We may update this
			Policy from time to time to reflect changes to our practices, operational, legal or
			regulatory reasons. If those are material, we will post this revised policy on this
			website.
		</p>
		<p>
			PLEASE NOTE THAT BY ACCESSING AND USING OUR WEBSITE AND OUR APPLICATIONS, YOU
			REPRESENT AND WARRANT THAT YOU HAVE READ AND UNDERSTOOD, AND AGREE TO THE TERMS OF
			THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH THIS TERM, PLEASE DO NOT ACCESS OR USE
			THIS WEBSITE AND SOLUTIONS.
		</p>
		<h3>1.Information Collection, Use, and Sharing</h3>
		<p>
			We collect different types of data according to your usage of application and website.
			Among the types of Personal Data that we may potentially collect are: Cookies, usage
			data, geographic location, email addresses, and demographic information. While
			providing most of these will be voluntary, the refusal to do so may prevent you from
			using some of our features and services.
		</p>
		<p>
			When you access our website, we may automatically collect and retain passive
			information, including your devices’ internet protocol address (IP), and information
			such as browser, operational system and platform, time zone, and usage info. We also
			use cookies and technologies (such as embedded scropts and web beacons) to help us
			effectively track usage of our website and applications, with the assistance of
			third-party analytics software (such as Google Analytics).
		</p>
		<h3>2.How do we use your information?</h3>
		<p>
			In our public website and applications, your personal information is collected to
			enhance your experience when using our services and applications. While you are
			generally able to block the sharing of information through configuration in your
			browser settings, this may prevent access or damage your experience in the usage of
			our services.
		</p>
		<p>
			Nugget.ai mission is to help people better understand their soft skills, helping them
			find opportunities for self-development and new career paths. At the same time, we
			help companies hire, retain and develop their talent, creating programs that use
			public and private data from their own companies. When using our applications, you
			understand that you are consenting that your information may be visible to your
			employer if part of one of these programs. If you chose to use our services and
			applications through a third-party application (such as job boards, recruiting
			services, and others), you are also agreeing to grant this third-party access to your
			Nugget.ai data.
		</p>
		<h3>3.Your Access to and Control Over Information</h3>
		<p>
			Nugget.ai retains your information provided upon registration for legitimate business
			purposes and to comply with the law, and adopts a responsible security framework
			(below) to protect your individual privacy rights and usage of your data.
		</p>
		<p>
			You may opt out of any future contacts from us at any time and have the rights to be
			informed, access, and exclusion of your data. Specifically, at Nugget.ai we are
			commited with the rationale of the GDPR framework, and have certain rights under
			European Law. Should you want to exercise these rights, you can do so at any time by
			contacting us via the email address (privacy@nugget.ai) or our contact form in the
			website.
		</p>
		<p>Note that some information may need to be retained as required by law.</p>
		<h3>4.Security Measures</h3>
		<p>
			We take precautions to protect your information. When you submit sensitive information
			via the website, your information is protected both online and offline.
		</p>
		<p>
			Wherever we collect sensitive information (such as your personal data), that
			information is encrypted and transmitted to us in a secure way. You can verify this by
			looking for a lock icon in the address bar and looking for "https" at the beginning of
			the address of the Web page.
		</p>
		<p>
			While we use encryption to protect sensitive information transmitted online, we also
			protect your information offline. Only employees who need the information to perform a
			specific job are granted access to personally identifiable information. The
			computers/servers in which we store personally identifiable information are kept in a
			secure environment.
		</p>
		<p>
			We host data and applications in top-tier cloud providers with recognized industry
			security certifications, as well as use software that has met highest standards in the
			industry. If you want to have access to that information, please request that at the
			email address privacy@nugget.ai, stating reasoning for that request.
		</p>
		<h3>5.Contact Information</h3>
		<p>
			If you feel that we are not abiding by this privacy policy, or should you have any
			questions, please contact us immediately via email at privacy@nugget.ai or by using
			the contacts below:
		</p>
		<br />
		<p>
			<i>
				<b>Nugget.ai Corp.</b>
			</i>
			<br />
			ATTN: Chief Executive Officer
			<br />
			185 Spadina Avenue
			<br />
			Toronto, ON M5T 2C6
			<br />
			Canada
		</p>
	</div>
)
