import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'
import actions from './actions'
import { getHeaders } from '../../utils/authUtil'
import { getEndpoint } from '../../utils/urlHelper'

export function* updateCandidate() {
	yield takeEvery(actions.UPDATE_CANDIDATE_REQUEST, function* ({ payload }) {
		const { challengeId, pipelineId, userId, data } = payload
		// userId.map((emailId, index) => {
		//   const params = {
		//     url: getEndpoint(`challenge/${challengeId}/${pipelineId}/${emailId}`),
		//     method: 'put',
		//     headers: getHeaders(),
		//     data,
		//   };
		//   try {
		//     const res = yield call(axios.request, params);
		//     yield put(actions.updateCandidateSuccess(data));
		//   } catch (err) {
		//     yield put(actions.updateCandidateFailed(err));
		//   }
		// })
	})
}

export function* storeCandidate() {
	yield takeEvery(actions.STORE_CANDIDATE_REQUEST, function* ({ payload }) {
		const { data } = payload
		yield put(actions.setCandidate(data))
	})
}

export function* getCandidate() {
	yield takeEvery(actions.GET_CANDIDATE_REQUEST, function* ({ payload }) {
		const { challengeId, pipelineId, userEmail } = payload
		const params = {
			url: getEndpoint(`challenge/${challengeId}/${pipelineId}/${userEmail}`),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.getCandidateSuccess(res.data.candidate))
		} catch (err) {
			yield put(actions.getCandidateFailed(err))
		}
	})
}

export function* deleteCandidate() {
	yield takeEvery(actions.DELETE_CANDIDATE_REQUEST, function* ({ payload }) {
		const { challengeId, pipelineId, userId } = payload
		const params = {
			url: getEndpoint(`challenge/${challengeId}/${pipelineId}/${userId}`),
			method: 'delete',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.deleteCandidateSuccess(res.data.candidate))
		} catch (err) {
			yield put(actions.deleteCandidateFailed(err))
		}
	})
}

export function* sendDCandidateEmail() {
	yield takeEvery(actions.SEND_CANDIDATE_EMAIL_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('challenge/sendEmailCandidate'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.sendEmailCandidateSuccess(res.data.candidate))
		} catch (err) {
			yield put(actions.sendEmailCandidateFailed(err))
		}
	})
}

export default function* candidateSagas() {
	yield all([
		fork(updateCandidate),
		fork(getCandidate),
		fork(storeCandidate),
		fork(deleteCandidate),
		fork(sendDCandidateEmail)
	])
}
