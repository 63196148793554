import adminReducer from './admin/reducer'
import authReducer from './auth/reducer'
import challengeReducer from './challenge/reducer'
import pipelineReducer from './pipeline/reducer'
import candidateReducer from './candidate/reducer'
import resultReducer from './result/reducer'
import sandboxReducer from './sandbox/reducer'
import categoryReducer from './category/reducer'
import chapterReducer from './chapter/reducer'
import featureReducer from './feature/reducer'

export default {
	adminReducer,
	authReducer,
	challengeReducer,
	pipelineReducer,
	candidateReducer,
	resultReducer,
	sandboxReducer,
	categoryReducer,
	chapterReducer,
	featureReducer
}
