import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'
import actions from './actions'
import challengeActions from '../challenge/actions'
import { getHeaders } from '../../utils/authUtil'
import { getEndpoint } from '../../utils/urlHelper'

export function* updatePipeline() {
	yield takeEvery(actions.UPDATE_PIPELINE_REQUEST, function* ({ payload }) {
		const { challenge_id, pipeline_id, data } = payload
		const params = {
			url: getEndpoint(`challenge/${challenge_id}/${pipeline_id}`),
			method: 'put',
			headers: getHeaders(),
			data
		}

		try {
			yield call(axios.request, params)
			yield put(actions.updatePipelineSuccess())
			yield put(challengeActions.getChallenge(challenge_id))
		} catch (err) {
			yield put(actions.updatePipelineFaileds(err))
		}
	})
}

export function* benchmarkPipeline() {
	yield takeEvery(actions.BENCHMARK_PIPELINE_REQUEST, function* ({ payload }) {
		const { challenge_id, pipeline_id, data } = payload
		const params = {
			url: getEndpoint(`challenge/benchmark/${challenge_id}/${pipeline_id}`),
			method: 'put',
			headers: getHeaders(),
			data
		}

		try {
			yield call(axios.request, params)
			yield put(actions.updatePipelineSuccess())
			yield put(challengeActions.getChallenge(challenge_id))
		} catch (err) {
			yield put(actions.updatePipelineFaileds(err))
		}
	})
}

export function* getPipelines() {
	yield takeEvery(actions.GET_PIPELINES_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('pipeline/'),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			console.log(res)
			yield put(actions.getPipelinesSuccess())
		} catch (err) {
			yield put(actions.getPipelinesFailed(err))
		}
	})
}

export function* getPipeline() {
	yield takeEvery(actions.GET_PIPELINE, function* ({ payload }) {
		const params = {
			url: getEndpoint(`pipeline/${payload.pipelineId}`),
			method: 'get',
			params: {
				userId: payload.userId === 'default' ? undefined : payload.userId
			},
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.getPipelineSuccess(res.data.pipeline))
		} catch (err) {
			yield put(
				actions.getPipelineFailed({
					msg: err.response.data.msg,
					img: err.response.data.errorImg
				})
			)
		}
	})
}

export function* setPipeline() {
	yield takeEvery(actions.SET_PIPELINE, function* ({ payload }) {
		const { pipelineId, pipelineData } = payload
		const params = {
			url: getEndpoint(`pipeline/${pipelineId}`),
			method: 'post',
			headers: getHeaders(),
			data: pipelineData
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.setPipelineSuccess(res.data.pipeline))
		} catch (err) {
			yield put(actions.setPipelineFailed(err))
		}
	})
}

export function* getChapterResult() {
	yield takeEvery(actions.GET_CHAPTER_RESULT_REQUEST, function* ({ payload }) {
		const { pipeline_id, chapter_id } = payload
		const params = {
			url: getEndpoint(`pipeline/result/${pipeline_id}/${chapter_id}`),
			method: 'get',
			headers: getHeaders()
		}
		try {
			const res = yield call(axios.request, params)
			const { result } = res.data
			yield put(actions.getChapterResultSuccess(result))
		} catch (err) {
			yield put(actions.getChapterResultFailed(err))
		}
	})
}

export default function* pipelineSagas() {
	yield all([
		fork(updatePipeline),
		fork(benchmarkPipeline),
		fork(getChapterResult),
		fork(getPipeline),
		fork(setPipeline),
		fork(getPipelines)
	])
}
