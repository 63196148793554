const actions = {
	GET_FEATURE_REQUEST: 'GET_FEATURE_REQUEST',
	GET_FEATURE_SUCCESS: 'GET_FEATURE_SUCCESS',
	GET_FEATURE_FAILED: 'GET_FEATURE_FAILED',

	getFeature: payload => ({
		type: actions.GET_FEATURE_REQUEST,
		payload
	}),
	getFeatureSuccess: payload => ({
		type: actions.GET_FEATURE_SUCCESS,
		payload
	}),
	getFeatureFailed: payload => ({
		type: actions.GET_FEATURE_FAILED,
		payload
	})
}

export default actions
