import { removeUser } from '../../utils/localStorageUtil'

const actions = {
	REGISTER_REQUEST: 'REGISTER_REQUEST',
	REGISTER_SUCCESS: 'REGISTER_SUCCESS',
	REGISTER_FAILED: 'REGISTER_FAILED',

	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILED: 'LOGIN_FAILED',

	LOGOUT_REQUEST: 'LOGOUT_REQUEST',
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
	LOGOUT_FAILED: 'LOGOUT_FAILED',

	GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
	GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
	GET_PROFILE_FAILED: 'GET_PROFILE_FAILED',

	UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
	UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
	UPDATE_PROFILE_FAILED: 'UPDATE_PROFILE_FAILED',

	DELETE_PROFILE_REQUEST: 'DELETE_PROFILE_REQUEST',
	DELETE_PROFILE_SUCCESS: 'DELETE_PROFILE_SUCCESS',
	DELETE_PROFILE_FAILED: 'DELETE_PROFILE_FAILED',

	registerRequest: payload => ({ type: actions.REGISTER_REQUEST, payload }),
	registerSuccess: payload => ({ type: actions.REGISTER_SUCCESS, payload }),
	registerFailed: payload => ({ type: actions.REGISTER_FAILED, payload }),

	loginRequest: payload => ({ type: actions.LOGIN_REQUEST, payload }),
	loginSuccess: payload => ({ type: actions.LOGIN_SUCCESS, payload }),
	loginFailed: payload => ({ type: actions.LOGIN_FAILED, payload }),

	logoutRequest: () => ({ type: actions.LOGOUT_REQUEST }),
	logoutSuccess: () => ({ type: actions.LOGOUT_SUCCESS }),
	logoutFailed: () => ({ type: actions.LOGOUT_FAILED }),

	getProfileRequest: () => ({ type: actions.GET_PROFILE_REQUEST }),
	getProfileSuccess: payload => ({
		type: actions.GET_PROFILE_SUCCESS,
		payload
	}),
	getProfileFailed: payload => {
		removeUser()
		return {
			type: actions.GET_PROFILE_FAILED,
			payload
		}
	},

	updateProfile: payload => ({
		type: actions.UPDATE_PROFILE_REQUEST,
		payload
	}),
	updateProfileSuccess: payload => ({
		type: actions.UPDATE_PROFILE_SUCCESS,
		payload
	}),
	updateProfileFailed: payload => ({
		type: actions.UPDATE_PROFILE_FAILED,
		payload
	}),

	deleteProfile: () => ({ type: actions.DELETE_PROFILE_REQUEST }),
	deleteProfileSuccess: () => ({ type: actions.DELETE_PROFILE_SUCCESS }),
	deleteProfileFailed: payload => ({
		type: actions.DELETE_PROFILE_FAILED,
		payload
	})
}

export default actions
