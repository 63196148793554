import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { env } from './constants/config'
import App from './containers/App/App'

Sentry.init({
	dsn: 'https://a72d9cb2006c49a3b290a10c78f12ddd@o427421.ingest.sentry.io/5374261',
	environment: env,
	enabled: process.env.NODE_ENV === 'production'
})

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById('root')
)
