import gatePipeline from '../images/gate_pipeline.svg'
import trashPipeline from '../images/trash_pipeline.svg'

const { origin: origin_url, hostname, protocol } = window.location
const regexIp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
const isLocal = hostname.includes('localhost') || regexIp.test(hostname)

const prod_url = process.env.REACT_APP_PROD_ASSESSMENT_URL

const getLocalUrl = port => `${protocol}//${hostname}:${port}`
console.log(getLocalUrl(3000))

export const env = process.env.REACT_APP_MODE || 'dev'
export const isDev = env === 'dev'

const isProd =
	origin_url === prod_url || origin_url.replace('www.', '') === prod_url.replace('www.', '')

export const SERVER_URL = process.env.REACT_APP_PROD_CORE_API_URL
export const SANDBOX_URL = process.env.REACT_APP_PROD_SANDBOX_URL

// export const SERVER_URL = isLocal
// 	? getLocalUrl(process.env.REACT_APP_CORE_API_LOCAL_PORT || 8081)
// 	: isProd
// 	? process.env.REACT_APP_PROD_CORE_API_URL
// 	: process.env.REACT_APP_DEV_CORE_API_URL

export const ANALYTICS_URL = isLocal
	? getLocalUrl(process.env.REACT_APP_ANALYTICS_LOCAL_PORT || 4201)
	: isProd
	? process.env.REACT_APP_PROD_ANALYTICS_URL
	: process.env.REACT_APP_DEV_ANALYTICS_URL

// export const SANDBOX_URL = isLocal
// 	? getLocalUrl(process.env.REACT_APP_SANDBOX_LOCAL_PORT || 4200)
// 	: isProd
// 	? process.env.REACT_APP_PROD_SANDBOX_URL
// 	: process.env.REACT_APP_DEV_SANDBOX_URL

export const AWS_CREDENTIALS = {
	bucketName: process.env.REACT_APP_AWS_BUCKETNAME,
	dirName: process.env.REACT_APP_AWS_PHOTO_DIRNAME,
	region: process.env.REACT_APP_AWS_REGION,
	accessKeyId: process.env.REACT_APP_AWS_ACCESSKEYID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET
}

export const AWS_RESUME_S3_CREDENTIALS = {
	bucketName: process.env.REACT_APP_AWS_BUCKETNAME,
	dirName: process.env.REACT_APP_AWS_RESUMES_DIRNAME,
	region: process.env.REACT_APP_AWS_REGION,
	accessKeyId: process.env.REACT_APP_AWS_ACCESSKEYID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET
}

export const pipelineErrorsImage = {
	gate: gatePipeline,
	trash: trashPipeline
}

export const PIXEL_ID = '2262723547138604'
