import React from 'react'
import { AppBar, Toolbar, Grid, IconButton, Button } from '@material-ui/core'
import './Header.scss'
import { SANDBOX_URL } from '../../constants/config'

const moveSignupPage = () => {
	console.log('----------------->>>>>>')
	console.log(SANDBOX_URL)
	window.open(`${SANDBOX_URL}/signup-talent`)
}

const Header = () => (
	<Grid>
		<AppBar className="AppBar" color="default" position="static">
			<Toolbar disableGutters eventkey={0} className="navbarItem">
				<IconButton href="/" className="brand" color="inherit" aria-label="Logo" />
				<div className="nav__icons">
					{/* <div className="lang-selection">
                <Select
                  className="lang-select"
                  value={i18nState.lang}
                  onChange={event =>
                    this.props.dispatch(setLanguage(event.target.value))
                  }
                  renderValue={value => (
                    <Grid container alignItems="center" justify="center">
                      <img src={flags[value]} alt={value} /> &nbsp;&nbsp;
                      {value.toUpperCase()}
                    </Grid>
                  )}
                  input={<OutlinedInput />}
                >
                  <MenuItem value="es">ES</MenuItem>
                  <MenuItem value="en">EN</MenuItem>
                </Select>
              </div> */}
				</div>

				<div className="jobs-page-top-buttons">
					<div className="job-page-top-btn-container">
						<Button
							className="jobs-page-top-button"
							variant="outlined"
							// color="primary"
							onClick={moveSignupPage}
							style={{
								boxShadow: 'none',
								color: 'white',
								borderColor: 'white',
								textTransform: 'capitalize'
							}}
						>
							Candidate? Create an account
						</Button>

						{/* <Button
              className="jobs-page-top-button"
              variant="contained"
              style={{
                boxShadow: 'none',
                backgroundColor: '#e1628d',
                borderColor: '#e1628d',
                color: 'white',
                textTransform: 'capitalize',
                marginLeft: '20px'
              }}
            >
              Company? Request to post
            </Button> */}
					</div>
				</div>
			</Toolbar>
		</AppBar>
	</Grid>
)

export default Header
