const actions = {
	UPDATE_PIPELINE_REQUEST: 'UPDATE_PIPELINE_REQUEST',
	UPDATE_PIPELINE_SUCCESS: 'UPDATE_PIPELINE_SUCCESS',
	UPDATE_PIPELINE_FAILED: 'UPDATE_PIPELINE_FAILED',

	BENCHMARK_PIPELINE_REQUEST: 'BENCHMARK_PIPELINE_REQUEST',
	BENCHMARK_PIPELINE_SUCCESS: 'BENCHMARK_PIPELINE_SUCCESS',
	BENCHMARK_PIPELINE_FAILED: 'BENCHMARK_PIPELINE_FAILED',

	GET_PIPELINES_REQUEST: 'GET_PIPELINES_REQUEST',
	GET_PIPELINES_SUCCESS: 'GET_PIPELINES_SUCCESS',
	GET_PIPELINES_FAILED: 'GET_PIPELINES_FAILED',

	GET_PIPELINE: 'GET_PIPELINE',
	GET_PIPELINE_SUCCESS: 'GET_PIPELINE_SUCCESS',
	GET_PIPELINE_FAILED: 'GET_PIPELINE_FAILED',

	SET_PIPELINE: 'SET_PIPELINE',
	SET_PIPELINE_SUCCESS: 'SET_PIPELINE_SUCCESS',
	SET_PIPELINE_FAILED: 'SET_PIPELINE_FAILED',

	GET_CHAPTER_RESULT_REQUEST: 'GET_CHAPTER_RESULT_REQUEST',
	GET_CHAPTER_RESULT_SUCCESS: 'GET_CHAPTER_RESULT_SUCCESS',
	GET_CHAPTER_RESULT_FAILED: 'GET_CHAPTER_RESULT_FAILED',

	updatePipeline: payload => ({
		type: actions.UPDATE_PIPELINE_REQUEST,
		payload
	}),
	updatePipelineSuccess: () => ({ type: actions.UPDATE_PIPELINE_SUCCESS }),
	updatePipelineFailed: payload => ({
		type: actions.UPDATE_PIPELINE_FAILED,
		payload
	}),

	benchmarkPipeline: payload => ({
		type: actions.BENCHMARK_PIPELINE_REQUEST,
		payload
	}),
	benchmarkPipelineSuccess: () => ({
		type: actions.BENCHMARK_PIPELINE_SUCCESS
	}),
	benchmarkPipelineFailed: payload => ({
		type: actions.BENCHMARK_PIPELINE_FAILED,
		payload
	}),

	getPipelines: payload => ({
		type: actions.GET_PIPELINES_REQUEST,
		payload
	}),
	getPipelinesSuccess: payload => ({
		type: actions.GET_PIPELINES_SUCCESS,
		payload
	}),
	getPipelinesFailed: payload => ({
		type: actions.GET_PIPELINES_FAILED,
		payload
	}),

	getChapterResult: payload => ({
		type: actions.GET_CHAPTER_RESULT_REQUEST,
		payload
	}),
	getChapterResultSuccess: payload => ({
		type: actions.GET_CHAPTER_RESULT_SUCCESS,
		payload
	}),
	getChapterResultFailed: payload => ({
		type: actions.GET_CHAPTER_RESULT_FAILED,
		payload
	}),

	getPipeline: payload => ({
		type: actions.GET_PIPELINE,
		payload
	}),
	getPipelineSuccess: payload => ({
		type: actions.GET_PIPELINE_SUCCESS,
		payload
	}),
	getPipelineFailed: payload => ({
		type: actions.GET_PIPELINE_FAILED,
		payload
	}),

	setPipeline: payload => ({
		type: actions.SET_PIPELINE,
		payload
	}),
	setPipelineSuccess: payload => ({
		type: actions.SET_PIPELINE_SUCCESS,
		payload
	}),
	setPipelineFailed: payload => ({
		type: actions.SET_PIPELINE_FAILED,
		payload
	})
}

export default actions
