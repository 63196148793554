import actions from './actions'

const initState = {
	chapter: {},
	chapters: [],
	chapterResult: [],
	isLoading: false,
	errorMsg: null,
	updateStatus: false,
	setScoreResult: false
}

export default function challengeReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.SET_ALREADY_TAKEN_CHAPTER_DATA:
			return {
				...state,
				chapterResult: [...state.chapterResult, payload],
				chapterResultId: payload._id
			}

		case actions.GET_SEL_CHAPTER_REQUEST:
			return {
				...state,
				isLoading: false
			}

		case actions.GET_SEL_CHAPTER_SUCCESS:
			return {
				...state,
				isLoading: true,
				chapters: payload
			}

		case actions.GET_SEL_CHAPTER_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}

		case actions.GET_CHAPTER_REQUEST:
			return {
				...state,
				isLoading: false
			}

		case actions.GET_CHAPTER_SUCCESS:
			return {
				...state,
				isLoading: true,
				chapter: payload
			}

		case actions.GET_CHAPTER_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}

		case actions.GET_SCHAPTER_RESULT_REQUEST:
			return {
				...state,
				isLoading: false
			}
		case actions.GET_SCHAPTER_RESULT_SUCCESS:
			return {
				...state,
				isLoading: false,
				chapterResult: payload
			}
		case actions.GET_SCHAPTER_RESULT_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}

		case actions.SET_CHAPTER_RESULT_REQUEST:
			return {
				...state,
				isLoading: false
			}
		case actions.SET_CHAPTER_RESULT_SUCCESS:
			return {
				...state,
				isLoading: false,
				chapterResult: [...state.chapterResult, payload],
				chapterResultId: payload._id
			}
		case actions.SET_CHAPTER_RESULT_FAILED:
			return {
				...state,
				isLoading: false
			}

		case actions.UPDATE_CHAPTER_RESULT_REQUEST:
			return {
				...state,
				isLoading: false
			}
		case actions.UPDATE_CHAPTER_RESULT_SUCCESS:
			return {
				...state,
				isLoading: false,
				// chapterResultId: payload,
				updateStatus: true
			}
		case actions.UPDATE_CHAPTER_RESULT_FAILED:
			return {
				...state,
				isLoading: false
			}

		case actions.SET_SCORE_VALUE_REQUEST:
			return {
				...state,
				isLoading: false,
				setScoreResult: false
			}
		case actions.SET_SCORE_VALUE_SUCCESS:
			return {
				...state,
				isLoading: false,
				// chapterResultId: payload,
				setScoreResult: true
			}
		case actions.SET_SCORE_VALUE_FAILED:
			return {
				...state,
				isLoading: false,
				setScoreResult: false
			}

		default:
			return state
	}
}
