import actions from './actions'

const initState = {
	pipeline: null,
	pipelineResult: null,
	isLoading: false,
	errorMsg: null,
	pipelines: [],
	chapterResult: {},
	ppStatus: false
}

export default function pipelineReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.GET_PIPELINES_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null
			}

		case actions.GET_PIPELINES_SUCCESS:
			return {
				...state,
				isLoading: false,
				pipelines: payload
			}

		case actions.GET_PIPELINES_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}

		case actions.GET_PIPELINE:
			return {
				...state,
				isLoading: true,
				errorMsg: null
			}

		case actions.GET_PIPELINE_SUCCESS:
			return {
				...state,
				isLoading: false,
				pipeline: payload
			}

		case actions.GET_PIPELINE_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}

		case actions.SET_PIPELINE:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				pipelineResult: null
			}

		case actions.SET_PIPELINE_SUCCESS:
			return {
				...state,
				isLoading: false,
				pipelineResult: payload
			}

		case actions.SET_PIPELINE_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}

		case actions.UPDATE_PIPELINE_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null
			}

		case actions.UPDATE_PIPELINE_SUCCESS:
			return {
				...state,
				isLoading: false
			}

		case actions.UPDATE_PIPELINE_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}
		case actions.UPDATE_PIPELINEUSERDETAIL_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null
			}

		case actions.UPDATE_PIPELINEUSERDETAIL_SUCCESS:
			return {
				...state,
				isLoading: false
			}

		case actions.UPDATE_PIPELINEUSERDETAIL_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}
		case actions.BENCHMARK_PIPELINE_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null
			}

		case actions.BENCHMARK_PIPELINE_SUCCESS:
			return {
				...state,
				isLoading: false
			}

		case actions.BENCHMARK_PIPELINE_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}

		case actions.GET_CHAPTER_RESULT_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null
			}

		case actions.GET_CHAPTER_RESULT_SUCCESS:
			return {
				...state,
				isLoading: false,
				chapterResult: payload
			}

		case actions.GET_CHAPTER_RESULT_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}

		default:
			return state
	}
}
