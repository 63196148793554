import React, { createContext, useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import iziToast from 'izitoast'
import { getEndpoint } from '../../utils/urlHelper'
import { getHeaders } from '../../utils/authUtil'

export const Context = createContext()

const getOptionLabel = option => {
	if (typeof option === 'string') {
		return option
	}
	if (option.inputValue) {
		return option.inputValue
	}
	return option.label
}

const SearchProvider = ({ children }) => {
	const [state, setState] = useState({
		mainSearch: '',
		location: '',
		keywords: []
	})
	const [allJobs, setAllJobs] = useState([])
	const [jobs, setJobs] = useState([])
	const [loading, setLoading] = useState(false)
	const [count, setCount] = useState(0)
	const [pageInfo, setPageInfo] = useState(null)
	const keywords = useMemo(() => state.keywords.map(getOptionLabel), [state.keywords])
	const handleChange = key => e => {
		if (typeof e !== 'object' || Array.isArray(e) || !e) {
			setState({
				...state,
				[key]: e
			})
		} else if (typeof e?.target?.value !== 'undefined') {
			setState({
				...state,
				[key]: e.target.value
			})
		}
	}
	useEffect(() => {
		getJobs()
		// eslint-disable-next-line
	}, [])
	const getJobs = async (page = 1) => {
		try {
			setLoading(true)
			const { data } = await axios.get(getEndpoint('job'), {
				data: {
					...state,
					keywords
				}
			})
			if (data) {
				setLoading(false)
				setJobs(data.items)
				setCount(data.count)
				const shallowAllJobs = [...allJobs]
				shallowAllJobs[page - 1] = data.items
				setAllJobs(shallowAllJobs)
				setPageInfo(data.pageInfo)
			}
		} catch (error) {
			setLoading(false)
			iziToast.error({
				title: 'Error',
				message: 'Failed to load the jobs'
			})
		}
	}
	const searchJobs = async (page = 1) => {
		try {
			setLoading(true)
			const { data } = await axios.get(getEndpoint('job'), {
				params: {
					...state,
					keywords,
					page
				},
				headers: getHeaders()
			})
			if (data) {
				setLoading(false)
				setJobs(data.items)
				const shallowAllJobs = [data.items]
				setAllJobs(shallowAllJobs)
				setPageInfo(data.pageInfo)
			}
		} catch (error) {
			setLoading(false)
			iziToast.error({
				title: 'Error',
				message: 'Failed to load the jobs'
			})
		}
	}
	return (
		<Context.Provider
			value={{
				state,
				handleChange,
				count,
				loading,
				jobs,
				pageInfo,
				getJobs,
				searchJobs,
				keywords
			}}
		>
			{children}
		</Context.Provider>
	)
}

export default SearchProvider
