const actions = {
	GET_CATEGORY_REQUEST: 'GET_CATEGORY_REQUEST',
	GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
	GET_CATEGORY_FAILED: 'GET_CATEGORY_FAILED',

	getCategory: payload => ({
		type: actions.GET_CATEGORY_REQUEST,
		payload
	}),
	getCategorySuccess: payload => ({
		type: actions.GET_CATEGORY_SUCCESS,
		payload
	}),
	getCategoryFailed: payload => ({
		type: actions.GET_CATEGORY_FAILED,
		payload
	})
}

export default actions
