const actions = {
	GET_CANDIDATE_REQUEST: 'GET_CANDIDATE_REQUEST',
	GET_CANDIDATE_SUCCESS: 'GET_CANDIDATE_SUCCESS',
	GET_CANDIDATE_FAILED: 'GET_CANDIDATE_FAILED',

	SET_CANDIDATE_REQUEST: 'SET_CANDIDATE_REQUEST',
	STORE_CANDIDATE_REQUEST: 'STORE_CANDIDATE_REQUEST',

	UPDATE_CANDIDATE_REQUEST: 'UPDATE_CANDIDATE_REQUEST',
	UPDATE_CANDIDATE_SUCCESS: 'UPDATE_CANDIDATE_SUCCESS',
	UPDATE_CANDIDATE_FAILED: 'UPDATE_CANDIDATE_FAILED',

	DELETE_CANDIDATE_REQUEST: 'DELETE_CANDIDATE_REQUEST',
	DELETE_CANDIDATE_SUCCESS: 'DELETE_CANDIDATE_SUCCESS',
	DELETE_CANDIDATE_FAILED: 'DELETE_CANDIDATE_FAILED',

	SEND_CANDIDATE_EMAIL_REQUEST: 'SEND_CANDIDATE_EMAIL_REQUEST',
	SEND_CANDIDATE_EMAIL_SUCCESS: 'SEND_CANDIDATE_EMAIL_SUCCESS',
	SEND_CANDIDATE_EMAIL_FAILED: 'SEND_CANDIDATE_EMAIL_FAILED',

	updateCandidate: payload => ({
		type: actions.UPDATE_CANDIDATE_REQUEST,
		payload
	}),
	updateCandidateSuccess: payload => ({
		type: actions.UPDATE_CANDIDATE_SUCCESS,
		payload
	}),
	updateCandidateFailed: payload => ({
		type: actions.UPDATE_CANDIDATE_FAILED,
		payload
	}),

	getCandidate: payload => ({ type: actions.GET_CANDIDATE_REQUEST, payload }),
	getCandidateSuccess: payload => ({
		type: actions.GET_CANDIDATE_SUCCESS,
		payload
	}),
	getCandidateFailed: payload => ({
		type: actions.GET_CANDIDATE_FAILED,
		payload
	}),

	deleteCandidate: payload => ({
		type: actions.DELETE_CANDIDATE_REQUEST,
		payload
	}),
	deleteCandidateSuccess: payload => ({
		type: actions.DELETE_CANDIDATE_SUCCESS,
		payload
	}),
	deleteCandidateFailed: payload => ({
		type: actions.DELETE_CANDIDATE_FAILED,
		payload
	}),

	sendEmailCandidate: payload => ({
		type: actions.SEND_CANDIDATE_EMAIL_REQUEST,
		payload
	}),
	sendEmailCandidateSuccess: payload => ({
		type: actions.SEND_CANDIDATE_EMAIL_SUCCESS,
		payload
	}),
	sendEmailCandidateFailed: payload => ({
		type: actions.SEND_CANDIDATE_EMAIL_FAILED,
		payload
	}),

	setCandidate: payload => ({ type: actions.SET_CANDIDATE_REQUEST, payload }),
	storeCandidate: payload => ({
		type: actions.STORE_CANDIDATE_REQUEST,
		payload
	})
}

export default actions
