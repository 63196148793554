import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'
import actions from './actions'
import { getHeaders } from '../../utils/authUtil'
import { getEndpoint } from '../../utils/urlHelper'

export function* getCategory() {
	yield takeEvery(actions.GET_CATEGORY_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('category'),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			let category = res.data.data
			yield put(actions.getCategorySuccess(category))
		} catch (err) {
			yield put(actions.getCategoryFailed(err))
		}
	})
}

export default function* challengeSagas() {
	yield all([fork(getCategory)])
}
