import actions from './actions'

const initState = {
	features: [],
	isLoading: false,
	errorMsg: null
}

export default function featureReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.GET_FEATURE_REQUEST:
			return {
				...state,
				isLoading: false
			}

		case actions.GET_FEATURE_SUCCESS:
			return {
				...state,
				isLoading: true,
				features: payload
			}

		case actions.GET_FEATURE_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}

		default:
			return state
	}
}
