import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'
import actions from './actions'
import { getHeaders } from '../../utils/authUtil'
import { getEndpoint } from '../../utils/urlHelper'

export function* getSelChapter() {
	yield takeEvery(actions.GET_SEL_CHAPTER_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('chapter/selChapters'),
			method: 'get',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.getSelChapterSuccess(res.data.data))
		} catch (err) {
			yield put(actions.getSelChapterFailed(err))
		}
	})
}

export function* getChapter() {
	yield takeEvery(actions.GET_CHAPTER_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`chapter/${payload}`),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.getChapterSuccess(res.data.data))
		} catch (err) {
			yield put(actions.getChapterFailed(err))
		}
	})
}

export function* getSChapterResult() {
	yield takeEvery(actions.GET_SCHAPTER_RESULT_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`pipeline/${payload.pipeline_id}/users/${payload.id}`),
			method: 'get',
			headers: getHeaders(),
			params: payload.type ? { type: payload.type } : undefined
		}
		try {
			const result = yield call(axios.request, params)
			const { data } = result.data
			yield put(actions.getSChapterResultSuccess(data))
		} catch (err) {
			if (err?.response) {
				const { status, data } = err.response
				yield put(actions.getSChapterResultFailed({ status, ...data }))
			}
		}
	})
}

export function* setChapterResult() {
	yield takeEvery(actions.SET_CHAPTER_RESULT_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('chapter/setResult'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}
		try {
			const result = yield call(axios.request, params)
			yield put(actions.setChapterResultSuccess(result.data.chapterResult))
		} catch (err) {
			yield put(actions.setChapterResultFailed(err))
		}
	})
}

export function* updateChapterResult() {
	yield takeEvery(actions.UPDATE_CHAPTER_RESULT_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('chapter/updateResult'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}
		try {
			yield call(axios.request, params)
			yield put(actions.updateChapterResultSuccess())
		} catch (err) {
			yield put(actions.updateChapterResultFailed(err))
		}
	})
}

export function* setScoreValue() {
	yield takeEvery(actions.SET_SCORE_VALUE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('setIndividualSValue'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}
		try {
			const result = yield call(axios.request, params)
			yield put(actions.setScoreValueSuccess(result.data.data))
		} catch (err) {
			yield put(actions.setScoreValueFailed(err))
		}
	})
}

export default function* chapterSagas() {
	yield all([
		fork(getSelChapter),
		fork(getChapter),
		fork(getSChapterResult),
		fork(setChapterResult),
		fork(updateChapterResult),
		fork(setScoreValue)
	])
}
