import React, { Component } from 'react'
import { Router, Route, Switch, withRouter } from 'react-router-dom'
import Loadable from 'react-loadable'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import ReactPixel from 'react-facebook-pixel'

import iziToast from 'izitoast'
import theme from './theme'
import Header from '../../components/Header/Header'
import './Root.scss'

import Loading from '../../components/Loading'
import { PIXEL_ID } from '../../constants/config'
import SearchProvider from '../JobsPage/Context'
import { isExpired, saveLogTime } from '../../utils/localStorageUtil'
import authAction from '../../redux/auth/actions'
import 'izitoast/dist/css/iziToast.min.css'

iziToast.settings({
	position: 'bottomLeft',
	maxWidth: '400px'
})

const { logoutRequest } = authAction

const NotFound = Loadable({
	loader: () => import('../Error404'),
	loading: Loading
})

const Jobs = Loadable({
	loader: () => import('../JobsPage'),
	loading: Loading
})

const Home = Loadable({
	loader: () => import('../Home/Home'),
	loading: Loading
})

const AssessmentPage = Loadable({
	loader: () => import('../AssessmentPage/AssessmentPage'),
	loading: Loading
})

const RAssessmentPage = Loadable({
	loader: () => import('../RAssessmentPage/RAssessmentPage'),
	loading: Loading
})

const CEnter = Loadable({
	loader: () => import('../CEnter/CEnter'),
	loading: Loading
})

const CLanding = Loadable({
	loader: () => import('../CLanding/CLanding'),
	loading: Loading
})

const CEnterInfo = Loadable({
	loader: () => import('../CEnterInfo/CEnterInfo'),
	loading: Loading
})

const COnboard = Loadable({
	loader: () => import('../COnboard/COnboard'),
	loading: Loading
})

const TakeTest = Loadable({
	loader: () => import('../TakeTest/TakeTest'),
	loading: Loading
})
const DemoTakeTest = Loadable({
	loader: () => import('../DemoTakeTest/DemoTakeTest'),
	loading: Loading
})

const CSignIn = Loadable({
	loader: () => import('../CSignIn/CSignIn'),
	loading: Loading
})
const Questionnaire = Loadable({
	loader: () => import('../Questionnaire/Questionnaire'),
	loading: Loading
})
const CRegister = Loadable({
	loader: () => import('../CRegister/CRegister'),
	loading: Loading
})

const Survey = Loadable({
	loader: () => import('../Survey/Survey'),
	loading: Loading
})

const isAssessmentPage = path => {
	const assessmentPageList = [
		'enter',
		'taketest',
		'preview',
		'assessment',
		'rassessment',
		'candidatelogin',
		'faq',
		'information',
		'survey',
		'landing',
		'user',
		'questionnaire'
	]
	for (const pageName of assessmentPageList) {
		if (path.includes(pageName)) {
			return true
		}
	}
	return false
}

class Root extends Component {
	componentDidMount() {
		const shareURL = `${window.location.hostname}`
		if (shareURL !== 'nuggetai.com') {
			return
		}
		ReactPixel.init(PIXEL_ID, {}, { debug: false, autoConfig: true })
		ReactPixel.pageView()
		ReactPixel.fbq('track', 'PageView')
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.handleCheck()
		}
	}

	handleCheck() {
		const { logoutRequest } = this.props
		if (isExpired()) {
			logoutRequest()
		} else {
			saveLogTime()
		}
	}

	render() {
		const { history } = this.props
		// console.log('root update', history.location.pathname);
		const isAssessment = isAssessmentPage(history.location.pathname)
		// const rootStyle = isAssessment ? 'test_container' : 'root__container'
		const rootStyle = isAssessment
			? 'test_container'
			: this.props.location.pathname === '/'
			? 'home__container'
			: 'root__container'

		const hasHeader = !isAssessment

		return (
			<MuiThemeProvider theme={theme}>
				<Router history={history}>
					<div className="wrapper">
						{hasHeader && <div className="header-back-part" />}
						{hasHeader && <Header />}
						<div className={rootStyle}>
							<Switch>
								<Route
									exact
									path="/"
									render={props => {
										return (
											<SearchProvider>
												<Jobs {...props} />
											</SearchProvider>
										)
									}}
								/>
								<Route path="/home" component={Home} />

								<Route
									path="/assessment/:assessmentId/:pipelineId/:userId"
									component={AssessmentPage}
								/>
								<Route
									path="/demo/:accountId/:pipelineId/:userId"
									component={AssessmentPage}
									render={props => {
										return <AssessmentPage demo {...props} />
									}}
								/>
								<Route
									path="/rassessment/:chapterId/:userUUId"
									component={RAssessmentPage}
								/>
								<Route path="/enter/:challengeId/:pipelineId" component={CEnter} />
								<Route path="/landing/:challengeId/:pipelineId" component={CLanding} />
								<Route path="/onboard/:challengeId/:pipelineId" component={COnboard} />
								<Route path="/taketest/:challengeId/:pipelineId" component={TakeTest} />
								<Route
									path="/preview/:categoryName/:storyId/:chapterId"
									component={DemoTakeTest}
								/>
								<Route
									path="/candidatelogin/:challengeId/:pipelineId/:userId"
									component={CSignIn}
								/>

								<Route path="/questionnaire" component={Questionnaire} />

								<Route
									path="/information/:challengeId/:pipelineId/:userId"
									component={CEnterInfo}
								/>
								<Route path="/candidateregister" component={CRegister} />
								<Route path="/survey/:challengeId/:pipelineId/:userId" component={Survey} />
								<Route component={NotFound} />
							</Switch>
						</div>
					</div>
				</Router>
			</MuiThemeProvider>
		)
	}
}

Root.propTypes = {
	isLoggedIn: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired,
	logoutRequest: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	isLoggedIn: state.authReducer.isLoggedIn,
	user: state.authReducer.user
})

const mapDispatchToProps = {
	logoutRequest
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root))
