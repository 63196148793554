import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'
import actions from './actions'
import authActions from '../auth/actions'

import { getHeaders } from '../../utils/authUtil'
import { getEndpoint } from '../../utils/urlHelper'

import { saveUser, saveToken, saveLogTime } from '../../utils/localStorageUtil'

export function* register() {
	yield takeEvery(actions.REGISTER_SAND_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/signup'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			const userInfo = res.data.user
			const success = res.data.success
			const token = res.data.token

			if (success === true) {
				userInfo['sandbox'] = true
				saveUser(userInfo)
				saveToken(token)
				saveLogTime()
				yield put(actions.registerTSuccess(userInfo))
				yield put(authActions.loginSuccess(userInfo))
			} else {
				yield put(actions.registerTFailed(res.data.msg))
			}
		} catch (err) {
			yield put(actions.registerTFailed(err.response.data.error))
		}
	})
}

export function* login() {
	yield takeEvery(actions.LOGIN_SAND_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/signin'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			const userInfo = res.data.user
			const success = res.data.success
			const token = res.data.token
			console.log('saga loginT:', res.data)
			if (success) {
				userInfo['sandbox'] = true
				saveUser(userInfo)
				saveToken(token)
				saveLogTime()
				yield put(actions.loginTSuccess(success))
				yield put(authActions.loginSuccess(userInfo))
			} else {
				yield put(actions.loginTFailed(res.data.msg))
			}
		} catch (err) {
			yield put(actions.loginTFailed(err.response.data.error))
		}
	})
}

export function* getAllChallenge() {
	yield takeEvery(actions.GET_ALL_CHALLENGE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('challenge/sandbox'),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			const { challenges } = res.data
			yield put(actions.getAllChallengeSuccess(challenges))
		} catch (err) {
			yield put(actions.getAllChallengeFailed(err))
		}
	})
}

export function* getEChapterResult() {
	yield takeEvery(actions.GET_ECHAPTER_RESULT_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`sandbox/getChapterResult/${payload}`),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			const { chapterResults } = res.data
			yield put(actions.getEChapterResultSuccess(chapterResults))
		} catch (err) {
			yield put(actions.getEChapterResultFailed(err))
		}
	})
}

export function* getSandboxUserdata() {
	yield takeEvery(actions.GET_SANDBOX_USERDATA_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`sandbox/getUserdata/${payload}`),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			const { sandData } = res.data
			yield put(actions.getSandboxUserdataSuccess(sandData))
		} catch (err) {
			yield put(actions.getSandboxUserdataFailed(err))
		}
	})
}

export function* getChallenge() {
	yield takeEvery(actions.GET_CHALLENGE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`challenge/${payload}`),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			const { challenge } = res.data
			yield put(actions.getChallengeSuccess(challenge))
		} catch (err) {
			yield put(actions.getChallengeFailed(err))
		}
	})
}

export function* setSandProfileInfo() {
	yield takeEvery(actions.SET_PROFILE_INFO_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/setprofiledetails'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			console.log(params)
			const res = yield call(axios.request, params)
			const { sanduser } = res.data
			yield put(actions.setSandProfileInfoSuccess(sanduser))
		} catch (err) {
			yield put(actions.setSandProfileInfoFailed(err))
		}
	})
}

export function* getSandboxData() {
	yield takeEvery(actions.GET_SANDDATA_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/getsandboxdata'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			const { sandData } = res.data
			if (Object.keys(sandData).length > 0) {
				yield put(actions.getSandDataSuccess(sandData))
			} else {
				yield put(actions.getSandDataFailed('no data'))
			}
		} catch (err) {
			yield put(actions.getSandDataFailed(err))
		}
	})
}

export function* getSandboxByPProfileData() {
	yield takeEvery(actions.GET_SANDDATA_PROFILE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`sandbox/getppsandboxdata/${payload}`),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			const { sandData } = res.data
			if (Object.keys(sandData).length > 0) {
				yield put(actions.getSandDataRequestByProfileSuccess(sandData))
			} else {
				yield put(actions.getSandDataRequestByProfileFailed('no data'))
			}
		} catch (err) {
			yield put(actions.getSandDataRequestByProfileFailed(err))
		}
	})
}

export function* updateProfileDetails() {
	yield takeEvery(actions.UPDATE_PROFILE_INFO_REQUEST, function* ({ payload }) {
		const { userUUId, data } = payload

		if (!Array.isArray(userUUId)) {
			const params = {
				url: getEndpoint(`sandbox/updateProfileDetails/${userUUId}`),
				method: 'put',
				headers: getHeaders(),
				data: [data]
			}

			try {
				const res = yield call(axios.request, params)

				yield put(actions.updateProfileDetailsSuccess(res))
			} catch (err) {
				yield put(actions.updateProfileDetailsFailed(err))
			}
		} else {
			for (var k = 0; k < userUUId.length; k++) {
				var arr = []

				for (var i = 0; i < data.addNewCell.length; i++) {
					if (data.addNewCell[i][k] !== undefined) {
						arr.push({
							field_name: data.newfield_name[i + 2],
							value: data.addNewCell[i][k],
							type: data.type
						})
					}
				}

				const params = {
					url: getEndpoint(`sandbox/updateProfileDetails/${userUUId[k]}`),
					method: 'put',
					headers: getHeaders(),
					data: arr
				}

				try {
					console.log(arr)
					const res = yield call(axios.request, params)
					yield put(actions.updateProfileDetailsSuccess(res))
				} catch (err) {
					yield put(actions.updateProfileDetailsFailed(err))
				}
			}
		}
	})
}

export function* updateSandboxRecord() {
	yield takeEvery(actions.UPDATE_SANDBOX_RECORD_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/updateSandboxRecord'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.updateSandboxRecordSuccess(res.data))
		} catch (err) {
			yield put(actions.updateSandboxRecordFailed(err))
		}
	})
}

export function* updateSandboxPProfile() {
	yield takeEvery(actions.UPDATE_SANDBOX_PPROFILE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/updateShareUrl'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			let regResult = res.data.updateResult
			if (regResult) {
				yield put(actions.updateSandPublicProfileSuccess(regResult))
			} else {
				yield put(actions.updateSandPublicProfileFailed(regResult))
			}
		} catch (err) {
			yield put(actions.updateSandPublicProfileFailed(err))
		}
	})
}

export function* getSandProfileDetails() {
	yield takeEvery(actions.GET_PROFILE_INFO_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/getSandProfileDetails'),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)

			const { sandData } = res.data
			if (Object.keys(sandData).length > 0) {
				yield put(actions.getProfileDetailsSuccess(sandData))
			} else {
				yield put(actions.getProfileDetailsFailed('no data'))
			}
		} catch (err) {
			yield put(actions.getProfileDetailsFailed(err))
		}
	})
}

export function* setBadgeValueRequest() {
	yield takeEvery(actions.SAVE_BADGE_VALUE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`sandbox/saveBadgeValue`),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.saveBadgeValueSuccess(res.data.data))
		} catch (err) {
			yield put(actions.saveBadgeValueFailed(err))
		}
	})
}

export default function* sandboxSagas() {
	yield all([
		fork(register),
		fork(login),
		fork(getAllChallenge),
		fork(getChallenge),
		fork(setSandProfileInfo),
		fork(getSandboxData),
		fork(getSandboxByPProfileData),
		fork(updateProfileDetails),
		fork(updateSandboxRecord),
		fork(getSandProfileDetails),
		fork(getEChapterResult),
		fork(getSandboxUserdata),
		fork(updateSandboxPProfile),
		fork(setBadgeValueRequest)
	])
}
