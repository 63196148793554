import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { i18nState } from 'redux-i18n'
import { createBrowserHistory as history } from 'history'
import { isDev } from '../constants/config'
import reducers from './reducers'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [isDev && logger, sagaMiddleware, thunk, routeMiddleware].filter(
	Boolean
)

const composeEnhancers =
	isDev && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose

const store = createStore(
	combineReducers({
		...reducers,
		router: routerReducer,
		i18nState
	}),
	composeEnhancers(applyMiddleware(...middlewares))
)

sagaMiddleware.run(rootSaga)

export { store, history }
