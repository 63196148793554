import { getToken, getSandToken } from './localStorageUtil'

export const getHeaders = () => {
	const auth = getToken()

	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
	if (auth) {
		headers.Authorization = auth
	}
	return headers
}

export const getFormHeaders = () => {
	const auth = getToken()
	const headers = {}
	if (auth) {
		headers.Authorization = auth
	}
	return headers
}

export const getSandHeaders = () => {
	const auth = getSandToken()
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
	if (auth) {
		headers.Authorization = auth
	}
	return headers
}

export const getSandFormHeaders = () => {
	const auth = getSandToken()
	const headers = {}
	if (auth) {
		headers.Authorization = auth
	}
	return headers
}
